// JavaScript Document

// Scripts written by Jordan Bearce @ Webilnx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleLeft       as fasFaAngleLeft       } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight      as fasFaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faCaretDown       as fasFaCaretDown       } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft       as fasFaCaretLeft       } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight      as fasFaCaretRight      } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircleQuestion  as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser      as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment         as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFolder          as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faPhone           as fasFaPhone           } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faTag             as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faXmark           as fasFaXmark           } from "@fortawesome/pro-solid-svg-icons/faXmark";

import { faPhone           as farFaPhone           } from "@fortawesome/pro-regular-svg-icons/faPhone";

import { faFacebookF       as fabFaFacebookF           } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn      as fabFaLinkedinIn          } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

/**
 * Add solid icons
 */
library.add(fasFaAngleLeft, fasFaAngleRight, fasFaBars, fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircleQuestion, fasFaCircleUser, fasFaClock, fasFaComment, fasFaFolder, fasFaMagnifyingGlass, fasFaPhone, fasFaTag, fasFaXmark);

/**
 * Add regular icons
 */
library.add(farFaPhone);

/**
 * Add brands icons
 */
library.add(fabFaFacebookF, fabFaLinkedinIn);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
